import React from 'react';
import Img1 from '../img/blog/imagem_familia.webp'
import { Link } from 'react-router-dom';

const BlogPageUm = () => {
    
    return(
        <section className='section h-[100%]'>

        <div className='mx-auto h-full w-full'>

            <div className='flex-col justify-around w-full h-28 mt-28 font-black bg-gray-700'>
                <h1 className='h1 text-5xl text-start text-white container mx-auto pt-8 p-4'>
                    Blog
                </h1>
            </div>
            <div className='flex-col w-full h-6 font-black bg-gray-800 cursor-pointer'>
                <div className='text-[14px] container mx-auto relative text-white font-thin justify-center items-center p-0.5'>
                <Link to={'/blog'} className='' >
                    BLOG
                </Link> / <span className='font-black'>DIVÓRCIO EXTRAJUDICIAL...</span>
                </div>
                <h1 className='h1 text-sm text-end text-zinc-400 container mx-auto pt-6 p-4'>
                    ultima atualização em Janeiro 2024
                </h1>
            </div>
            <div className='flex-col justify-center lg:flex-row mt-10 p-4'>

                <div className='flex flex-col lg:flex-row container mx-auto gap-10 justify-center items-center'>
                    <div className='flex-col container mx-auto relative lg:mt-10 mt-2 lg:flex-row p-2'>
                        <div className=''>
                                <img className="rounded-md w-[540px] h-[260px]" src={Img1} alt=''></img>
                        </div>
                        <h1 className='text-2xl lg:text-5xl font-black mt-6'>
                            DIVÓRCIO EXTRAJUDICIAL, COM FILHOS MENORES, É POSSÍVEL?
                        </h1>
                            <h2 className='mt-2 text-lg text-start'>
                                No Brasil, o divórcio extrajudicial é possível, inclusive quando há filhos menores envolvidos,
                                sendo uma modalidade mais rápida, econômica e menos burocrática de encerrar o casamento, realizado em cartório,
                                desde que preenchidos alguns requisitos.
                                Existem algumas condições específicas para que esse tipo de divórcio ocorra:<br/><br/>
                                •	O casal deve estar de comum acordo sobre a dissolução conjugal <br/>
                                •	Devem ser maiores e capazes <br/>
                                •	A guarda, convivência e alimentos dos filhos já devem estar resolvidas judicialmente.<br/>
                                •	Partes assistidas por advogados<br/>
                                •	Não pode haver litígio entre as partes <br/><br/>
                                Dessa forma, com todos esses requisitos preenchidos, as partes poderão ir até
                                um Tabelionato de Notas e realizar o divórcio extrajudicial. 
                                Este procedimento visa resguardar direitos dos filhos e garantirque todas as questões parentais
                                já estejam solucionadas,além de agilizar eventual partilha de bens.
                            </h2>
                            {/* <div className='rounded-full w-28 h-10 flex bg-gray-700 text-white text-thin text-[12px] justify-center items-center mt-4'>
                            <Link to={'/blog'} className='' >
                                voltar
                            </Link>
                            </div> */}
                    </div>
                </div>
        </div>
    </div>
</section>

    )
};

export default BlogPageUm;