import React from 'react';
import Image1 from '../img/home/image1.webp';
import { BsWhatsapp } from 'react-icons/bs';
import Icon1 from '../img/home/IconFamily.svg';
import Icon2 from '../img/home/IconLaw2.svg';
import Icon3 from '../img/home/IconLaw3.svg';
import Icon4 from '../img/home/IconLaw4.svg';
import Icon5 from '../img/home/IconLaw5.svg';
import Icon6 from '../img/home/IconLaw6.svg';
import Check from '../img/home/correct.png';
import { motion } from 'framer-motion';
import { transition1 } from '../transitions';
import { fadeIn } from '../variants';

const Home = () => {
  return (
  <motion.section
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  exit={{ opacity: 0 }}
  transition={transition1}
  className='section h-[100%]'>

    <div className='container mx-auto h-full w-full relative'>
      <div className='flex flex-col justify-center'>
        <div className='w-full md:mt-20 p-4 pt-40 lg:pt-0 lg:pb-0 lg:w-auto z-10 lg:absolute flex flex-col justify-center items-center lg:items-start'>
          <h1 className='h1 lg:mt-56 md:text-8xl'>
            Advocacia <br /> & Consultoria
          </h1>
          <div className='text-[22px] lg:text-[40px] font-secondary mb-4 lg:mb-12 lg:font-extralight font-light'>
            Direito de Família & Sucessões, <br />
            Imobiliário e Cível. <br />
            <div className='lg:text-lg text-sm font-extrabold mt-2'>OAB/SP 491.406</div>
          </div>
          <a href='https://api.whatsapp.com/send?phone=5516981567778&text=Ol%C3%A1,%20achei%20seu%20contato%20pelo%20site%20e%20gostaria%20de%20algumas%20informa%C3%A7%C3%B5es...'
          target='_blank'
          rel="noopener noreferrer">
          <button to={'/contact'} target='_blank' className='btn transition ease-in-out delay-150 bg-gray-700 hover:-translate-y-1 hover:scale-110 hover:bg-[#B69D8F] duration-250 rounded-full h-18 mb-[30px] font-extralight font-secondary mt-8 lg:mt-0'>
              entre em contato
            <BsWhatsapp className='ml-2 mb-0.5' />
          </button></a>
        </div>

        <div className='flex justify-end max-h-100 lg:max-h-max z-0'>
          <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          transition={transition1}
          className='relative lg:-right-40 over'>
            <img src={Image1} alt='imagehome' className='flex-row' />
          </motion.div>
        </div>
      </div>

      <motion.div
      variants={fadeIn('right', 0.3 )}
      initial='hidden'
      whileInView={'show'}
      viewport={{ once: false, amount: 0.3 }}
      className='text-center mt-10'>
        <h1 className='text-center lg:text-[60px] text-5xl font-black font-primary lg:mt-32 italic'>
          Atendimento Personalizado
        </h1>
        <h2 className='text-center lg:text-[42px] font-black italic font-primary text-2xl mt-4 text-gray-700'>
          Entenda como funciona
        </h2>
        
      </motion.div>

      <div className='flex flex-col lg:flex-row lg:gap-x-12 items-center justify-center gap-12 mt-10 p-1'>
        <div className='box-border h-[320px] w-[360px] p-4 rounded-[18%] bg-gray-800 shadow-2x'>
          <img src={Icon1} alt='imagehome' className='rounded-full items-center justify-center lg:ml-28 xl:ml-32 ml-32 w-14 h-14' />
            <h1 className='mt-8 text-2xl text-center font-black text-gray-300'>ATENDIMENTO PRESENCIAL E ONLINE</h1>
            <h2 className='text-center mt-4 text-[15px] text-zinc-300'>Os atendimentos podem ser realizados presencialmente em Franca/SP ou por chamada de vídeo (WhatsApp ou Google Meet) em todo Brasil e no mundo. </h2>
        </div>
        
        <div className='box-border h-[320px] w-[360px] p-4 rounded-[18%] bg-gray-800 shadow-2xl'>
          <img src={Icon2} alt='imagehome' className='rounded-full items-center justify-center lg:ml-28 xl:ml-32 ml-32 w-14 h-14' />
            <h1 className='mt-8 text-2xl text-center font-black text-zinc-300'>QUESTÕES SENSÍVEIS</h1>
            <h2 className='text-center mt-4 text-[15px] text-zinc-300'>O advogado familiarista está acostumado a lidar com questões mais sensíveis e por isso, acolhe e entende melhor aquele cliente. </h2>
        </div>
        
        <div className='box-border h-[320px] w-[360px] p-4 rounded-[18%] bg-gray-800 shadow-2xl'>
          <img src={Icon3} alt='imagehome' className='rounded-full items-center justify-center lg:ml-28 xl:ml-32 ml-32 w-14 h-14' />
            <h1 className='mt-8 text-2xl text-center font-black text-zinc-300'>EXECUÇÃO DO PLANO DEFINIDO</h1>
            <h2 className='text-center mt-4 text-[15px] text-zinc-300'>Conte com profissionais que atuam exclusivamente na área de família para te ajudar neste momento tão delicado. </h2>
        </div>
      </div>

      <div className='flex flex-col lg:flex-row lg:gap-x-12 items-center justify-center gap-12 mt-20 p-1'>
        <div className='box-border h-[320px] w-[360px] p-4 rounded-[18%] bg-gray-800 shadow-2xl'>
          <img src={Icon4} alt='imagehome' className='rounded-full items-center justify-center lg:ml-28 ml-32 xl:ml-32 w-14 h-14' />
            <h1 className='mt-8 text-2xl text-center font-black text-zinc-300'>PACIÊNCIA E ATENÇÃO</h1>
            <h2 className='text-center mt-4 text-[15px] text-zinc-300'>O Direito de Família lida com questões delicadas, que demandam extrema paciência e atenção às pessoas envolvidas no caso. </h2>
        </div>
        
        <div className='box-border h-[320px] w-[360px] p-4 rounded-[18%] bg-gray-800 shadow-2xl'>
          <img src={Icon5} alt='imagehome' className='rounded-full items-center justify-center lg:ml-28 xl:ml-32 ml-32 w-14 h-14' />
            <h1 className='mt-8 text-2xl text-center font-black text-zinc-300'>DIRETO NO WHATSAPP</h1>
            <h2 className='text-center mt-4 text-[15px] text-zinc-300'> A contratação do serviço pode ser feito diretamente pelo WhatsApp. O cliente é respondido rapidamente pela equipe, que faz o possível para atender mesmo fora do horário comercial. </h2>
        </div>
        
        <div className='box-border h-[320px] w-[360px] p-4 rounded-[18%] bg-gray-800 shadow-2xl'>
          <img src={Icon6} alt='imagehome' className='rounded-full items-center justify-center lg:ml-28 xl:ml-32 ml-32 w-14 h-14' />
            <h1 className='mt-8 text-2xl text-center font-black text-zinc-300'>AGENDA FLEXÍVEL</h1>
            <h2 className='text-center mt-4 text-[15px] text-zinc-300'>Com agendamento, conseguimos atender fora do horário comercial. Não fique esperando por uma resposta até o próximo dia útil. </h2>
        </div>
        </div>
    
        <div className='mt-6 mx-auto text-center items-center justify-center'>
        <a href='https://api.whatsapp.com/send?phone=5516981567778&text=Ol%C3%A1,%20achei%20seu%20contato%20pelo%20site%20e%20gostaria%20de%20algumas%20informa%C3%A7%C3%B5es...'
        target='_blank'
        rel="noopener noreferrer">
        <button
          className='btn transition ease-in-out delay-150 bg-gray-700 hover:-translate-y-1 hover:scale-110 hover:bg-[#B69D8F] duration-250 h-18 w-80 text-center font-secundary font-extralight text-[15px] rounded-full mx-auto'>
            FALAR COM ESPECIALISTA
            <BsWhatsapp className='ml-2 mb-1' />
        </button></a>
        </div>
          <div className='text-center'>
            <h3 className='text-md mt-1 font-secondary font-semibold mx-auto italic'>Respondemos no mesmo dia</h3>
          </div>

          <motion.div
          variants={fadeIn('left', 0.3 )}
          initial='hidden'
          whileInView={'show'}
          viewport={{ once: false, amount: 0.3 }}>
            <h1 className='lg:text-[60px] font-primary text-center mt-10 text-5xl font-black italic'>Está precisando de ajuda?</h1>
            <h2 className='lg:text-[40px] lg:mt-4 font-primary text-center text-2xl font-black italic'> Alguns serviços prestados</h2>
          </motion.div>
          <div className='flex flex-col lg:flex-row lg:gap-x-20 items-center justify-center mt-6'>
            <div className='btn h-16 w-80 rounded-full shadow-lg border-4 border-solid bg-gradient-to-r from-[#B69D8F] to-gray-800 lg:text-sm text-white mt-5'>
              Divórcio <img src={Check} alt='iconcheck' className='w-7 ml-2'></img>
            </div>
            <div className='btn h-16 w-80 rounded-full mt-6 shadow-lg border-4 border-solid bg-gradient-to-r from-[#B69D8F] to-gray-800 lg:text-sm text-white'>
              Guarda e visitação <img src={Check} alt='iconcheck' className='w-7 ml-2'></img>
            </div>
            <div className='btn h-16 w-80 rounded-full mt-6 shadow-lg border-4 border-solid bg-gradient-to-r from-[#B69D8F] to-gray-800 lg:text-sm text-white'>
              Partilha de bens <img src={Check} alt='iconcheck' className='w-7 ml-2' />
            </div>
            <div className='btn h-16 w-80 rounded-full mt-6 shadow-lg border-4 border-solid bg-gradient-to-r from-[#B69D8F] to-gray-800 lg:text-sm text-white'>
              Pensão Alimentícia <img src={Check} alt='iconcheck' className='w-7 ml-2'/>
            </div>
            </div>

            <div className='flex flex-col lg:flex-row lg:gap-x-20 items-center justify-center mt-8 p-1'>
            <div className='btn h-16 w-80 rounded-full shadow-lg border-4 border-solid bg-gradient-to-r from-[#B69D8F] to-gray-800 lg:text-sm text-white lg:mt-6'>
              Contratos<img src={Check} alt='iconcheck' className='w-7 ml-2' />
            </div>
            <div className='btn h-16 w-80 rounded-full mt-6 shadow-lg border-4 border-solid bg-gradient-to-r from-[#B69D8F] to-gray-800 lg:text-sm text-white'>
              União Estável <img src={Check} alt='iconcheck' className='w-7 ml-2' />
            </div>
            <div className='btn h-16 w-80 rounded-full mt-6 shadow-lg border-4 border-solid bg-gradient-to-r from-[#B69D8F] to-gray-800 lg:text-sm text-white'>
              Inventário <img src={Check} alt='iconcheck' className='w-7 ml-2' />
            </div>
            <div className='btn h-16 w-80 rounded-full mt-6 shadow-lg border-4 border-solid bg-gradient-to-r from-[#B69D8F] to-gray-800 lg:text-sm text-white'>
              Testamento <img src={Check} alt='iconcheck' className='w-7 ml-2' />
            </div>
            </div>

            <div className='flex flex-col lg:flex-row lg:gap-16 items-center justify-center mt-8 p-1'>
            <div className='btn h-16 w-80 rounded-full shadow-lg border-4 border-solid bg-gradient-to-r from-[#B69D8F] to-gray-800 lg:text-sm text-white lg:mt-6'>
              Usucapião <img src={Check} alt='iconcheck' className='w-7 ml-1' />
            </div>
            <div className='btn h-16 w-80 rounded-full mt-6 shadow-lg border-4 border-solid bg-gradient-to-r from-[#B69D8F] to-gray-800 lg:text-sm text-white'>
              Responsabilidade Civil<img src={Check} alt='iconcheck' className='w-7 ml-2' />
            </div>
            <div className='btn h-16 w-80 rounded-full mt-6 shadow-lg border-4 border-solid bg-gradient-to-r from-[#B69D8F] to-gray-800 lg:text-sm text-white'>
              Regularização de imóvel <img src={Check} alt='iconcheck' className='w-7 ml-2' />
            </div>
            <div className='btn h-16 w-80 rounded-full mt-6 shadow-lg border-4 border-solid bg-gradient-to-r from-[#B69D8F] to-gray-800 lg:text-sm text-white'>
              Indenização <img src={Check} alt='iconcheck' className='w-7 ml-2' />
            </div>
            </div>

    <div>
        <motion.div
        variants={fadeIn('right', 0.3 )}
        initial='hidden'
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        className='text-center mt-10'>
          <h1 className='lg:text-[60px] font-primary text-5xl font-black italic'>Guiada por princípios, comprometida com a excelência e a justiça.</h1>
        </motion.div>
    <div className='mt-8 flex flex-col lg:flex-row lg:gap-x-8 lg:justify-center text-center text-1xl'>
      <div className='lg:justify-center'>
      <h1 className='h1 mt-6 lg:text-[60px] text-[40px] text-start ml-4'>
            Missão
          </h1>
          <h2 className='text-start ml-4 mt-4'>
          Minha missão como advogada é prestar serviços jurídicos de excelência,
          de forma a atender às necessidades e expectativas dos meus clientes,
          contribuindo assim para a realização de seus objetivos e a resolução do seus problemas.
          Acredito que a advocacia é uma ferramenta poderosa para a construção de uma sociedade
          mais justa e solidária, e estou empenhada em exercê-la com responsabilidade e compromisso.
          </h2>
          </div>
          <div className='lg:justify-center'>
          <h1 className='h1 mt-6 lg:text-[60px] text-[40px] text-start ml-4'>
            Valores
          </h1>
          <h2 className='text-start ml-4 mt-4'>
          Meus valores como advogada são baseados na ética, na transparência e no respeito à dignidade humana.
          Busco sempre agir com integridade e coerência, em conformidade com os princípios e normas que
          regem a profissão e estabelecer relações de confiança e respeito.
          A ética é um valor fundamental para mim. Entendo que a atuação de um(a) advogado(a) deve estar
          sempre pautada pela honestidade e integridade, seguindo as normas e princípios que regem a profissão.
          Por isso, busco agir de forma transparente e coerente, estabelecendo relações de confiança com meus clientes e colegas.
          </h2>
          </div>
          <div className='lg:justify-center'>
          <h1 className='h1 mt-6 lg:text-[60px] text-[40px] text-start ml-4'>
            Visão
          </h1>
          <h2 className='text-start ml-4 mt-4'>
          Minha visão como advogada é contribuir para o aprimoramento da justiça e do direito,
          por meio da atuação profissional qualificada e comprometida com a solução pacífica de
          conflitos e a defesa dos direitos humanos. Almejo ser reconhecida como uma advogada
          referência em minha área de atuação, e contribuir para a formação de uma sociedade mais justa,
          solidária e respeitosa dos valores democráticos.
          </h2>
          </div>
      </div>
    </div>
    </div>
  </motion.section>

  );
};

export default Home;