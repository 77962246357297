import React from 'react';
import Img2 from '../img/blog/imagem_pessoasredesocial.webp'
import { Link } from 'react-router-dom';

const BlogPageDois = () => {
    return(
        <section className='section h-[100%]'>

        <div className='mx-auto h-full w-full'>

            <div className='flex-col justify-around w-full h-28 mt-28 font-black bg-gray-700'>
                <h1 className='h1 text-5xl text-start text-white container mx-auto pt-8 p-4'>
                    Blog
                </h1>
            </div>
            <div className='flex-col w-full h-6 font-black bg-gray-800 cursor-pointer'>
                <div className='text-[14px] container mx-auto relative text-white font-thin justify-center items-center p-0.5'>
                <Link to={'/blog'} className='' >
                    BLOG
                </Link> / <span className='font-black'>REDES SOCIAIS: PODEM SER...</span>
                </div>
                <h1 className='h1 text-sm text-end text-zinc-400 container mx-auto pt-6 p-4'>
                    ultima atualização em Janeiro 2024
                </h1>
            </div>
            <div className='flex-col justify-center lg:flex-row mt-10 p-4'>

                <div className='flex flex-col lg:flex-row container mx-auto gap-10 justify-center items-center'>
                    
                    <div className='flex-col container mx-auto relative lg:mt-10 mt-2 lg:flex-row p-2'>
                        <div className=''>
                                <img className="rounded-md w-[540px] h-[260px]" src={Img2} alt=''></img>
                        </div>
                        <h1 className='text-2xl lg:text-5xl font-black mt-6'>
                            REDES SOCIAIS: PODEM SER PATRIMÔNIOS PASSÍVEIS DE SEREM PARTILHADOS EM INVENTÁRIO OU DISSOLUÇÃO CONJUGAL
                        </h1>
                            <h2 className='mt-2 lg:text-lg text-lg text-start'>
                            Na realidade atual, bens virtuais, como as contas em redes sociais, podem ser considerados ativos passíveis
                            de partilha em processos de divórcio ou inventário, especialmente se tiverem valor econômico.<br/><br/>  
                            Por exemplo, os influencers digitais fazem publicidade em sua conta, adquirindo retorno econômico com isso,
                            esse dinheiro adquirido pode entrar no patrimônio a ser partilhado.
                            Porém, deve-se sempre
                            observar o regime de bens que está vigente no casamento.<br/><br/>  
                            Outra dica importante, os usuários desses perfis podem estabelecer por meio de
                            testamentos ou pactos antenupciais, quem irá administrar o perfil em caso de morte,
                            bem como se dará a divisão dos bens, e outras cláusulas que acharem necessárias!
                            Mas, esse assunto está muito recente, não se tem uma legislação específica para este tema,
                            mas temos que ficar de olho em cada jurisprudência dos Tribunais, para adequar em cada caso concreto.
                            </h2>
                            {/* <div className='rounded-full w-28 h-10 flex bg-gray-700 text-white text-thin text-sm justify-center items-center mt-4'>
                            <Link to={'/blog'} className='' >
                                voltar
                            </Link>
                            </div> */}
                    </div>
                </div>
        </div>
    </div>
</section>

    )
};

export default BlogPageDois;