import React from 'react';
import Img1 from '../img/blog/imagem_contratacaodeseguro.webp'
import { Link } from 'react-router-dom';

const BlogPageQuatro = () => {
    return(
        <section id="topo"  className='section h-[100%]'>
            <div className='mx-auto h-full w-full'>
                <div className='flex-col justify-around w-full h-28 mt-28 font-black bg-gray-700'>
                    <h1 className='h1 text-5xl text-start text-white container mx-auto pt-8 p-4'>
                        Blog
                    </h1>
                </div>
            <div className='flex-col w-full h-6 font-black bg-gray-800 cursor-pointer'>
                <div className='text-[14px] container mx-auto relative text-white font-thin justify-center items-center p-0.5'>
                <Link to={'/blog'} className='' >
                    BLOG
                </Link> / <span className='font-black'>CONTRATAÇÃO DE SEGURO...</span>
                </div>
                <h1 className='h1 text-sm text-end text-zinc-400 container mx-auto pt-6 p-4'>
                    ultima atualização em Janeiro 2024
                </h1>
            </div>
            <div className='flex-col justify-center lg:flex-row mt-10 p-4'>
                <div className='flex flex-col lg:flex-row container mx-auto gap-10 justify-center items-center'>
                    
                    <div className='flex-col container mx-auto relative lg:mt-10 mt-2 lg:flex-row p-2'>
                        <div className=''>
                                <img className="rounded-md w-[540px] h-[260px]" src={Img1} alt=''></img>
                        </div>
                        <h1 className='text-3xl lg:text-5xl font-black mt-6'>
                            CONTRATAÇÃO DE SEGURO DE VIDA E A SUA IMPORTÂNCIA PARA O PLANEJAMENTO SUCESSÓRIO
                        </h1>
                            <h2 className='mt-2 text-md text-start'>
                            Na realidade brasileira, os indivíduos não estão muito familiarizados com a contratação de seguro de vida,
                            ao contrário dos países mais desenvolvidos seja pela falta de informação ou planejamento, tendo em vista que se trata
                            de um assunto delicado pra grande maioria da população brasileira. O fato é que, muita gente não sabe, mas a contratação
                            desse tipo de seguro pode trazer uma grande proteção patrimonial e sucessória para seus herdeiros.<br/><br/>

                            A sucessão patrimonial e partilha de bens são fatores primordiais para alavancar as disputas familiares
                            entre herdeiros, cônjuges, companheiros e ascendentes, em momento tão delicado, colocando em jogo valores
                            econômicos e direitos do querido ente familiar que veio a falecer. Assim, os bens que deveriam ser
                            partilhados em comum acordo, acabam sendo alvo de processos judiciais e muitas vezes transformando em obstáculo ou inventário sem fim.<br/><br/>

                            Observa-se que, a solução para essas desavenças é a realização de um planejamento sucessório,
                            assunto que está em alta nos últimos tempos e como explicam Hironaka & Tartuce (2019)
                            é uma forma de concretizar a última vontade de disposição dos bens do falecido:<br/><br/>

                            "Em suma, pode-se afirmar que o planejamento sucessório é o conjunto de atos e negócios jurídicos
                            efetuados por pessoas que mantêm entre si alguma relação jurídica familiar ou sucessória,
                            com o intuito de idealizar a divisão do patrimônio de alguém, evitando conflitos
                            desnecessários e procurando concretizar a última vontade da pessoa cujos bens formam o seu objeto."<br/><br/>

                            Corroborado a isso, no entendimento de Madaleno (2014), há diversos mecanismos que
                            complementam e auxiliama sucessão patrimonial mais adequada de um indivíduo, instrumentos
                            podem ser de maior ou menor utilidade que em conjunto formam uma planificação do patrimônio
                            e prevê quais são os propósitos dos bens, para quem e como os dispor depois da morte do titular.<br/><br/>

                            Dessa forma, evidente que em qualquer momento da vida é preciso se organizar, planejar, analisar todos os eventos possíveis
                            e impossíveis de acontecer, e no fim da vida, não seria diferente, assim demonstra-se que imprevistos podem
                            acontecer e se o individuo são estiver preparado financeiramente e sucessoriamente, acaba por gerar uma enorme disputa familiar.<br/><br/>

                            Com isso, quando se trata de agilidade e reduzir os custos com os processos de inventário, é então que
                            falamos da importância da contratação de seguro de vida, para proteger o patrimônio do segurado
                            em caso de morte, garantindo um valor predeterminado aos seus beneficiários.<br/><br/>

                            <b> POR QUE CONTRATAR UM SEGURO DE VIDA PARA SUCESSÃO PATRIMONIAL? </b><br/>

                            A contratação do seguro de vida é um diferencial, pois os valores que estão na apólice não
                            entram no inventário do falecido, se trata de um serviço contratado, não estando sujeitos
                            às leis sobre sucessão de bens. Dessa forma, caso ocorra o evento morte, os valores serão
                            repassados imediatamente aos beneficiários, sendo um procedimento muito mais ágil.
                            A diferença para os processos de inventário é que este envolve custos elevados, motivo pelo
                            qual é preciso que se faça pagamento do imposto (ITCMD) em prazo estabelecido em lei sob pena
                            de incidência de juros, além de taxas e outras despesas.<br/><br/>

                            O seguro de vida, em suma não há despesas adicionais e é isenta de tributação,
                            sendo que o dinheiro vai para as mãos dos beneficiários mais rapidamente, garantindo
                            o pagamento do valor acordado independentemente do tempo de vigência do contrato, sendo
                            melhor do que esperar a finalização de um processo de inventário que pode demorar meses ou até anos.<br/><br/>
                    
                            Outro fator muito importante, é a impenhorabilidade do seguro de vida em razão de processos judiciais,
                            além de o capital estipulado não estar sujeito às dívidas do segurado e não
                            é considerado herança, conforme dispõe o art. 794 do Código Civil:<br/><br/>

                            "Art. 794. No seguro de vida ou de acidentes pessoais para o caso de morte,
                            o capital estipulado não está sujeito às dívidas do segurado,
                            nem se considera herança para todos os efeitos de direito."<br/><br/>

                            Ainda há que se dizer que sobre o capital das apólices do seguro
                            ou de recurso pago pela morte do segurado, não incide
                            imposto de renda, causa de isenção prevista no art. 6º, inciso XIII da Lei n.º 7.713/88:<br/><br/>

                            "Art. 6º Ficam isentos do imposto de renda os seguintes rendimentos percebidos por pessoas físicas:
                            XIII - capital das apólices de seguro ou pecúlio pago por morte do segurado,
                            bem como os prêmios de seguro restituídos em qualquer caso, inclusive no de renúncia do contrato;"<br/><br/>

                            Para exemplificar, a jurisprudência caminha no mesmo sentido:<br/><br/>

                            AGRAVO DE INSTRUMENTO - SEGURO DE VIDA - PAGAMENTO DO PRÊMIO DEVIDO AO BENEFICIÁRIO - 
                            LIVRE INDICAÇÃO DO SEGURADO - SEGURO QUE NÃO INTEGRA A HERANÇA - RECURSO DESPROVIDO. 1. O art. 794
                            do Código Civil assegura que "no seguro de vida e de acidentes pessoais para o caso de morte,
                            o capital estipulado não está sujeito às dívidas do segurado, nem se considera herança
                            para todos os efeitos de direito."  2. Assim, a quantia decorrente de contrato de seguro
                            não é considerada como herança, razão pela qual não integra o acervo hereditário, pois o
                            titular da indenização securitária é o terceiro designado pelo falecido.
                            (TJMS. Agravo de Instrumento n. 1406257-51.2020.8.12.0000, Rio Verde de Mato Grosso, 2ª Câmara Cível,
                            Relator (a):  Des. Fernando Mauro Moreira Marinho, j: 16/07/2021, p:  20/07/2021)<br/><br/>


                            Ademais, o advogado Ezequiel Pereira da Silva (2020), dispõe que nos seguros de vida,
                            o segurado pode indicar qualquer indivíduo como seu beneficiário, e na ausência
                            de estipulação ou caso o beneficiário venha a falecer, que os valores da apólice serão
                            pagos por metade ao cônjuge não separado judicialmente e a outra metade aos herdeiros,
                            observando a ordem de vocação hereditária disposta no art. 1.829 do Código Civil.<br/><br/>

                            Portanto, importante esclarecer que, o seguro de vida é uma indenização que
                            visa proteger o titular dos bens e sua família ou amigos diante dessas
                            situações de gastos e eventos inesperados, cujo valor será destinado
                            à pessoa que estiver indicada na apólice como beneficiária,
                            se tratando de um mecanismo indispensável no planejamento sucessório.

                            </h2>
                            {/* <div className='rounded-full w-28 h-10 flex bg-gray-700 text-white text-thin text-[12px] justify-center items-center mt-4'>
                                <Link to={'/blog'} className='' >
                                    voltar
                                </Link>
                            </div> */}
                    </div>
                </div>
        </div>
    </div>
</section>

    )
};

export default BlogPageQuatro;