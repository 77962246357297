import React from 'react';
import ImgFamilia from '../img/portfolio/ImgFamilia.webp';
import ImgCivil from '../img/portfolio/ImgCivil.webp';
import ImgImobiliario from '../img/portfolio/ImgImobiliario.webp';
import { BsChevronDoubleRight } from 'react-icons/bs';


const Portfolio = () => {
  return (
  <section className='section h-[100%]'>
    <div className='container mx-auto h-full w-full relative'>
      <div className='flex flex-col justify-center lg:flex-row lg:mt-12'>
        <div className='mt-32 lg:flex-row ml-3 p-2'>
          <h1 className='text-center lg:text-start lg:text-[60px] lg:flex-row text-5xl font-black font-primary mt-10 italic'>
            Direito de Família
          </h1>
          <h2 className='mt-2 lg:text-lg text-sm text-start'>
          Um advogado especializado em Direito de Família tem como principal função atuar na defesa
          dos interesses de seus clientes em questões relacionadas ao âmbito familiar. Isso pode incluir
          a elaboração de acordos pré-nupciais, representação em processos de divórcio, guarda de filhos,
          pensão alimentícia, adoção, entre outros temas. O advogado também pode atuar em questões
          relacionadas à sucessão e herança, como a elaboração de testamentos e inventários. Além disso,
          é papel do advogado especializado em Direito de Família oferecer orientações e esclarecimentos
          aos seus clientes, buscando sempre a melhor solução para cada caso específico.
          </h2>
        </div>
        <div className='flex justify-end lg:mt-52 lg:ml-12 mt-5 p-2'>
          <div className='relative over rounded-md'>
            <img src={ImgFamilia} alt='image1' className='flex-row rounded-[10%]' />
          </div>
        </div>
      </div>
        <a href='https://api.whatsapp.com/send?phone=5516981567778&text=Ol%C3%A1,%20achei%20seu%20contato%20pelo%20site%20e%20gostaria%20de%20algumas%20informa%C3%A7%C3%B5es...'
              target='_blank'
              rel="noopener noreferrer">
          <button className='md:ml-64 lg:mt-6 mt-4 btn bg-gradient-to-r from-[#B69D8F] transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 h-8 w-60 text-center ml-16 font-secundary font-extralight text-[15px] rounded-full lg:ml-44 '>saiba mais <BsChevronDoubleRight className='ml-2' />
          </button></a>

      <div className='flex flex-col justify-center lg:flex-row rounded-md p-2'>
      <div className='lg:mt-10 mt-6 lg:flex-row ml-3'>
        <h1 className='text-center lg:text-start lg:text-[60px] lg:flex-row text-5xl font-black font-primary mt-10 italic'>
          Direito Civil
        </h1>
        <h2 className='mt-2 lg:text-lg text-sm text-start'>
        Um advogado especializado em Direito Civil atua em questões que envolvem as relações entre particulares,
        como contratos, indenizações, responsabilidade civil, propriedade, posse, dentre outras. Dessa forma, ele
        tem como principal função prestar orientações jurídicas, representar seus clientes em negociações e processos
        judiciais, elaborar contratos e outros documentos legais, além de buscar a solução mais adequada para cada caso específico.
        Em linhas gerais, o advogado especializado em Direito Civil lida com questões que envolvem o patrimônio,
        a propriedade e as obrigações decorrentes das relações jurídicas entre particulares. <br /> Ele busca garantir que
        os direitos e interesses de seus clientes sejam preservados e defendidos dentro do que determina a legislação civil.
        </h2>
      </div>
        <div className='flex justify-start lg:mt-40 lg:ml-12 mt-5 rounded-md p-2'>
          <div className='relative over rounded-md'>
            <img src={ImgCivil} alt='image1' className='flex-row rounded-[10%]' />
          </div>
        </div>
      </div>
      <a href='https://api.whatsapp.com/send?phone=5516981567778&text=Ol%C3%A1,%20achei%20seu%20contato%20pelo%20site%20e%20gostaria%20de%20algumas%20informa%C3%A7%C3%B5es...'
            target='_blank'
            rel="noopener noreferrer">
      <button className='md:ml-64 lg:mt-6 mt-4 btn bg-gradient-to-r from-[#B69D8F] transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 h-8 w-60 text-center ml-16 font-secundary font-extralight text-[15px] rounded-full lg:ml-44'>saiba mais <BsChevronDoubleRight className='ml-2' />
      </button></a>

      <div className='flex flex-col justify-center lg:flex-row rounded-md p-2'>
      <div className='lg:mt-10 lg:flex-row ml-3'>
        <h1 className='text-center lg:text-start lg:text-[60px] lg:flex-row text-5xl font-black font-primary mt-10 italic'>
          Direito Imobiliário
        </h1>
        <h2 className='mt-2 lg:text-lg text-sm text-start'>
        Um advogado especializado em Direito Imobiliário atua em questões relacionadas a bens imóveis,
        como compra e venda de imóveis, regularização de imóveis, locação, desapropriação, usucapião,
        condomínio, entre outras.<br /> O advogado Imobiliário pode prestar serviços como orientação na escolha
        do imóvel e na elaboração de contratos de compra e venda, além de regularizar a análise da
        documentação do imóvel e verificar a existência de eventuais problemas que possam prejudicar
        a transação Imobiliária.
        Além disso, ele pode atuar na regularização de imóveis, buscando a obtenção de documentos e
        alvarás necessários para a legalização da propriedade. Na área de locação, o advogado pode
        elaborar contratos e prestar consultoria aos locatários e locadores.
        </h2>
      </div>
        <div className='flex justify-start lg:mt-40 lg:ml-12 mt-5 p-2'>
          <div className='relative over rounded-md lg:mt-16'>
            <img src={ImgImobiliario} alt='image1' className='flex-row rounded-[10%]' />
          </div>
        </div>
      </div>
      <a href='https://api.whatsapp.com/send?phone=5516981567778&text=Ol%C3%A1,%20achei%20seu%20contato%20pelo%20site%20e%20gostaria%20de%20algumas%20informa%C3%A7%C3%B5es...'
            target='_blank'
            rel="noopener noreferrer">
      <button className='md:ml-64 mt-4 btn bg-gradient-to-r from-[#B69D8F] transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 h-8 w-60 text-center ml-16 font-secundary font-extralight text-[15px] rounded-full lg:ml-44'>saiba mais <BsChevronDoubleRight className='ml-2' />
      </button></a>
    </div>
  </section>

  );
};

export default Portfolio;