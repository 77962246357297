import React from 'react';
import Img1 from '../img/blog/imagem_familia.webp';
import Img2 from '../img/blog/imagem_pessoasredesocial.webp';
import Img3 from '../img/blog/imagem_casaltriste.webp';
import Img4 from '../img/blog/imagem_contratacaodeseguro.webp';
import { Link } from 'react-router-dom';

const Blog = () => {
    return(
        
        <section className='section h-[100%]'>

        <div className='mx-auto h-full w-full'>

            <div className='flex-col justify-around w-full h-28 mt-28 font-black bg-gray-700'>
                <h1 className='h1 text-5xl text-start text-white container mx-auto pt-8 p-4'>
                    Blog
                </h1>
            </div>
            <div className='flex-col w-full h-6 font-black bg-gray-800 cursor-pointer'>
                {/* <div className='text-[14px] container mx-auto relative text-white font-thin justify-center items-center p-0.5'>
                        <b>Blog</b> /
                        <Link to={'/blogconteudo'} className='' >
                                Conteúdo
                                flkm
                            </Link>
                </div> */}
                <h1 className='h1 text-sm text-end text-zinc-400 container mx-auto pt-6 p-4'>
                    ultima atualização em Janeiro 2024
                </h1>
            </div>

            <div className='flex-col justify-center lg:flex-row p-4'>

                <div className='flex flex-col lg:flex-row container mx-auto gap-2 justify-center items-center lg:mt-10'>
                        <div className='flex-col container mx-auto relative mt-2 lg:flex-row p-2'>
                            <h1 className='text-2xl lg:text-4xl font-black'>
                                CONTRATAÇÃO DE SEGURO DE VIDA E A SUA IMPORTÂNCIA PARA O PLANEJAMENTO SUCESSÓRIO
                            </h1>
                                <h2 className='mt-2 lg:text-lg text-md text-start'>
                                    Na realidade brasileira, os indivíduos não estão muito
                                    familiarizados com a contratação de seguro de vida,
                                    ao contrário dos países mais desenvolvidos seja
                                    pela falta de informação ou planejamento [...]
                                </h2>
                                <div className='rounded-full w-40 h-10 flex text-white text-thin text-[12px] justify-center items-center mt-2 transition ease-in-out delay-150 bg-gray-700 hover:-translate-y-1 hover:scale-110 hover:bg-[#B69D8F] duration-250'>
                                    <Link to={'/blogpagequatro'} className='' >
                                        Continuar Lendo...
                                    </Link>
                                </div>
                        </div>
                        <div className='flex-col box-shadow'>
                                <img className="rounded-lg w-[720px] h-[240px] md:w-[760px] md:h-[240px]" src={Img4} alt=''></img>
                        </div>
                    </div>

                    <div className='flex flex-col lg:flex-row container mx-auto gap-2 justify-center items-center lg:mt-20'>
                        <div className='flex-col container mx-auto relative mt-2 lg:flex-row p-2'>
                            <h1 className='text-2xl lg:text-4xl font-black mt-10 lg:mt-20'>
                                DIVÓRCIO EXTRAJUDICIAL, COM FILHOS MENORES, É POSSÍVEL?
                            </h1>
                                <h2 className='mt-2 lg:text-lg text-md text-start'>
                                No Brasil, o divórcio extrajudicial é possível,
                                inclusive quando há filhos menores envolvidos,
                                sendo uma modalidade mais rápida, econômica e menos
                                burocrática de encerrar o casamento, realizado em cartório [...]
                                </h2>
                                <div className='rounded-full w-40 h-10 flex text-white text-thin text-[12px] justify-center items-center mt-2 transition ease-in-out delay-150 bg-gray-700 hover:-translate-y-1 hover:scale-110 hover:bg-[#B69D8F] duration-250'>
                                    <Link to={'/blogpageum'} className='' >
                                        Continuar Lendo...
                                    </Link>
                                </div>
                        </div>
                        <div className='flex-col'>
                                <img className="rounded-lg w-[720px] h-[240px] md:w-[760px] md:h-[240px]" src={Img1} alt=''></img>
                        </div>
                </div>

                <div className='flex flex-col lg:flex-row container mx-auto gap-2 justify-center items-center lg:mt-20'>
                    <div className='flex-col container mx-auto relative mt-2 lg:flex-row p-2'>
                        <h1 className='text-2xl lg:text-4xl font-black mt-10 lg:mt-20'>
                            REDES SOCIAIS: PODEM SER PATRIMÔNIOS PASSÍVEIS DE SEREM PARTILHADOS EM INVENTÁRIO OU DISSOLUÇÃO CONJUGAL 
                        </h1>
                            <h2 className='mt-2 lg:text-lg text-md text-start'>
                                Na realidade atual, bens virtuais, como as contas
                                em redes sociais, podem ser considerados ativos
                                passíveis de partilha em processos de divórcio
                                ou inventário, especialmente se tiverem valor econômico [...]
                            </h2>
                            <div className='rounded-full w-40 h-10 flex bg-gray-700 text-white text-thin text-[12px] justify-center items-center mt-2 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-[#B69D8F] duration-250'>
                            <Link to={'/blogpagedois'} className='' >
                                Continuar Lendo...
                            </Link>
                            </div>
                    </div>
                    <div className='flex-col box-shadow '>
                            <img className="rounded-lg w-[720px] h-[240px] md:w-[760px] md:h-[240px]" src={Img2} alt=''></img>
                    </div>
                </div>


                <div className='flex flex-col lg:flex-row container mx-auto gap-2 justify-center items-center lg:mt-20'>
                    <div className='flex-col container mx-auto relative mt-2 lg:flex-row p-2'>
                        <h1 className='text-2xl lg:text-4xl font-black mt-10 lg:mt-20'>
                            OBJETIVO DE NÃO CONSTITUIR FAMÍLIA, PODE DESCARACTERIZAR UNIÃO ESTÁVEL
                        </h1>
                            <h2 className='mt-2 lg:text-lg text-md text-start'>
                                O Tribunal de Justiça do Amapá fixou entendimento no
                                processo nº 00232844920188030001, de que a Ação de Reconhecimento
                                e posterior Dissolução de União Estável c/c Partilha de Bens,
                                não merecia ser procedente [...]
                            </h2>
                            <div className='rounded-full w-40 h-10 flex text-white text-thin text-[12px] justify-center items-center mt-2 transition ease-in-out delay-150 bg-gray-700 hover:-translate-y-1 hover:scale-110 hover:bg-[#B69D8F] duration-250'>
                                <Link to={'/blogpagetres'} className='' >
                                    Continuar Lendo...
                                </Link>
                            </div>
                    </div>
                    <div className='flex-col box-shadow '>
                            <img className="rounded-lg w-[720px] h-[240px] md:w-[760px] md:h-[240px]" src={Img3} alt=''></img>
                    </div>
                </div>
        </div>
    </div>
</section>

    )
};

export default Blog;