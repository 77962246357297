/* eslint-disable jsx-a11y/alt-text */
import logo from '../img/rodape/newlogo.svg'
import { BsInstagram, BsEnvelope, BsWhatsapp, BsGeoAlt } from 'react-icons/bs'


const Rodape = () => {
    return (
    <footer className="w-full flex justify-center bg-gray-700 text-white mt-10">
    <div className="h-96">
        <div className="flex flex-col justify-center">
        <div className='mt-8'>
            <h1 className='mb-10 lg:w-96 w-80 ml-16 lg:ml-4 border rounded-full text-center text-[12px]'>ATENDIMENTO IMEDIATO 24H/7 DIAS POR SEMANA </h1>
        </div>
        <div className='mt-2 lg:text-1xl text-lg mr-16 font-medium'>
        <div className='cursor-pointer flex font-secundary'>
            <BsGeoAlt  className='ml-16 mr-3 mt-1'/> Franca-SP
        </div>
            <a href='https://api.whatsapp.com/send?phone=5516981567778&text=Ol%C3%A1,%20peguei%20seu%20contato%20pelo%20site%20e%20gostaria%20de%20algumas%20informa%C3%A7%C3%B5es...'
            target='_blank'
            rel="noopener noreferrer">
        <div className='flex font-secundary mt-3'>
            <BsWhatsapp  className='ml-16 mr-3 mt-1'/> (16) 98156-7778
        </div></a>
            <a href='https://www.instagram.com/adv.isabellatjorge/?igshid=YmMyMTA2M2Y%3D'
            target='_blank'
            rel="noopener noreferrer">
        <div className='flex  font-secundary mt-3'>
            <BsInstagram  className='ml-16 mr-3 mt-1'/> adv.isabellatjorge
        </div></a>
        <div className='cursor-pointer flex font-secundary mt-3'>
            <BsEnvelope  className='ml-16 mr-3 mt-1'/> adv.isabellatoninjorge@hotmail.com
        </div>
        </div>
        <a href='https://www.instagram.com/jc.desenvolvedor/'
            target='_blank'
            rel="noopener noreferrer">
        <div className="text-1xl lg:mt-10 mt-10">
            <img src={logo} className="cursor-pointer w-18 lg:ml-36 ml-32" />
        </div></a>
        <div className="flex flex-row text-lg justify-center items-baseline">
            <div className="whitespace-nowrap flex lg:ml-58">Todos os direitos reservados © 2023.</div>
        </div>
        </div>
        </div>
    </footer>



);
};

export default Rodape;