import React from 'react';
import { BsInstagram } from 'react-icons/bs'
import { ImLinkedin2, ImWhatsapp } from 'react-icons/im';


const Socials = () => {
  return <div className='hidden xl:flex'>
    <ul className='flex gap-x-2'>
      <li>
      <a href='https://api.whatsapp.com/send?phone=5516981567778&text=Ol%C3%A1,%20peguei%20seu%20contato%20pelo%20site%20e%20gostaria%20de%20algumas%20informa%C3%A7%C3%B5es...'
        target='_blank'
        rel="noopener noreferrer">
        <ImWhatsapp />
        </a>
      </li>
      <li>
        <a href='https://www.instagram.com/adv.isabellatjorge/?igshid=YmMyMTA2M2Y%3D'
          target='_blank'
          rel="noopener noreferrer">
        <BsInstagram />
        </a>
      </li>
      <li>
        <a href='https://www.linkedin.com/in/isabella-tonin-jorge-688684214'
          target='_blank'
          rel="noopener noreferrer">
        <ImLinkedin2 />
        </a>
      </li>
    </ul>
  </div>;
};

export default Socials;
