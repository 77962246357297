import React from 'react';
import Img3 from '../img/blog/imagem_casaltriste.webp'
import { Link } from 'react-router-dom';

const BlogPageTres = () => {
    return(
        <section id="topo"  className='section h-[100%]'>
            <div className='mx-auto h-full w-full'>
                <div className='flex-col justify-around w-full h-28 mt-28 font-black bg-gray-700'>
                    <h1 className='h1 text-5xl text-start text-white container mx-auto pt-8 p-4'>
                        Blog
                    </h1>
                </div>
            <div className='flex-col w-full h-6 font-black bg-gray-800 cursor-pointer'>
                <div className='text-[14px] container mx-auto relative text-white font-thin justify-center items-center p-0.5'>
                <Link to={'/blog'} className='' >
                    BLOG
                </Link> / <span className='font-black'>OBJETIVO DE NÃO CONSTITUIR...</span>
                </div>
                <h1 className='h1 text-sm text-end text-zinc-400 container mx-auto pt-6 p-4'>
                    ultima atualização em Janeiro 2024
                </h1>
            </div>
            <div className='flex-col justify-center lg:flex-row mt-10 p-4'>
                <div className='flex flex-col lg:flex-row container mx-auto gap-10 justify-center items-center'>
                    
                    <div className='flex-col container mx-auto relative lg:mt-10 mt-2 lg:flex-row p-2'>
                        <div className=''>
                                <img className="rounded-md w-[540px] h-[260px]" src={Img3} alt=''></img>
                        </div>
                        <h1 className='text-3xl lg:text-5xl font-black mt-6'>
                            OBJETIVO DE NÃO CONSTITUIR FAMÍLIA, PODE DESCARACTERIZAR UNIÃO ESTÁVEL
                        </h1>
                            <h2 className='mt-2 text-md text-start'>
                                O Tribunal de Justiça do Amapá fixou entendimento no processo nº 00232844920188030001,
                                de que a Ação de Reconhecimento e posterior Dissolução de União Estável c/c Partilha de Bens,
                                não merecia ser procedente.<br/><br/>

                                No caso concreto, houve um relacionamento amoroso entre a autora e o réu, mas,
                                ficou comprovado que era apenas um namoro qualificado; não havia propósito
                                de constituir família e nem viviam como se casados fossem.<br/><br/>

                                Assim, não havendo provas da união estável havida entre as partes,
                                também não há que se falar em partilha de bens,
                                pois o art. 1723 do CC dispõe: “Para que se configure união estável é preciso:<br/>

                                <b>1- convivência pública, contínua e duradoura;<br/>
                                2- objetivo de constituir família.<br /> 
                                O que não ocorreu no processo.
                                </b>
                            </h2>
                            {/* <div className='rounded-full w-28 h-10 flex bg-gray-700 text-white text-thin text-[12px] justify-center items-center mt-4'>
                                <Link to={'/blog'} className='' >
                                    voltar
                                </Link>
                            </div> */}
                    </div>
                </div>
        </div>
    </div>
</section>

    )
};

export default BlogPageTres;