import React from 'react';
import Socials from './Socials';
import Logo  from '../img/header/logo.svg';
import MobileNav from './MobileNav';
import { Link } from 'react-router-dom';
// import Menu from './ScrollToTop';

const Header = () => {  
  return <header className='bg-[#FFFF] fixed w-full px-[10px] z-30 h-[120px] flex items-center shadow-lg'>
    <div className='flex flex-col lg:flex-row lg:items-center w-full justify-around'>
      <Link to={'/'} className=''>
        <div>
        <img className="md:w-[360px] w-[280px]"src={Logo} alt="" />
        </div>
      </Link>
      <nav className='text-[14px] hidden xl:flex gap-x-8 font-semibold'>
        <Link
          to={'/'}
          className='text-gray-800 font-bold  hover:text-[#dbc3b4] transition'
          >
          Início
        </Link>
        <Link
          to={'/areasdeatuacao'}
          className='text-gray-800 font-bold hover:text-[#dbc3b4] transition'
        >
          Áreas de Atuação
        </Link>
        <Link
          to={'/sobremim'}
          className='text-gray-800 font-bold hover: hover:text-[#dbc3b4] transition'
        >
          Sobre Mim
        </Link>
        <Link
          to={'/blog'}
          className='text-gray-800 font-bold hover: hover:text-[#dbc3b4] transition'
        >
          Blog
        </Link>
        <Link
          to={'/contato'}
          className='text-gray-800 font-bold hover: hover:text-[#dbc3b4] transition'
        >
          Contato
        </Link>
      </nav>
    </div>
      <Socials />
      <MobileNav />
  </header>
};

export default Header;
