import React from 'react';
import ImgAbout from '../img/about/imageabout.webp';
import { transition1 } from '../transitions';
import { motion } from 'framer-motion';

const About = () => {
  return (
  <motion.section
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  exit={{ opacity: 0 }}
  transition={transition1}
  className='section h-full'>
    
    <div className='container mx-auto h-full relative'>
      <div className='flex flex-col lg:flex-row h-full items-center justify-center gp-x-24 text-center lg:text-left lg:pt-16'>
        <div className='mt-6 lg:mt-20 lg:mr-12 flex-1 max-h-120 lg:max-h-max order-2 lg:order-none'>
          <img src={ImgAbout} alt='' className='' />
        </div>
        <div className='flex-1 pt-24 pb-30 lg:pt-0 lg:w-auto z-10 flex flex-col justify-center items-center lg:items-start'>
          <div className='h1 mt-10 md:text-7xl p-4'>Sobre Mim</div>
          <div className='max-w-lg text-start lg:text-[18px] p-4'>
          Advogada, inscrita na <b>OAB/SP 491.406</b>, formada pela Faculdade de Direito de Franca (FDF),
          pós-graduada em Direito de Familia e Sucessões pela Legale Educacional.
          <b> Possuo experiência há mais de 5 anos</b> em escritórios de advocacia, os quais fizeram aprimorar minha comunicação,
          argumentação e solução de problemas, em diversas áreas do direito, principalmente em direito de família e sucessões.
          O escritório atua no <b>sistema full service</b> de modo a atender as demandas de nossos clientes, consultiva ou contenciosa,
          sempre com o objetivo de conquistar o melhor resultado possível em cada demanda seja ela judicial ou extrajudicial,através de soluções estratégicas e lógicas.<br/><br/>
          <b>Atuamos em todo o território nacional</b>, colococando em prática todos nossos conhecimentos e pesquisa,
          <b> a resolver questões jurídicas, com profissionalismo e dedicação.</b> 
          </div>
        </div>
      </div>
        <div className='mt-16 ml-7 lg:ml-96 text-center items-center justify-center'>
        </div>
    </div>
  </motion.section>
  );
};

export default About;
