import Header from './components/Header';
import AnimRoutes from './components/AnimRoutes';
import { BrowserRouter as Router} from 'react-router-dom';
import Rodape from './components/Rodape';

const App = () => {
  return (
    <div className='overflow-scroll overflow-x-hidden flex flex-col h-screen'>
    <div className=''>
    <Router>
      <Header />
      <AnimRoutes />
    </Router>
    </div>
    <Rodape />
    </div>
);
};

export default App;
