import React from 'react';
import { useState } from 'react';
import emailjs from '@emailjs/browser';
import ModalMessage from '../components/ModalMessage/ModalMessage.js';
import { transition1 } from '../transitions';
import { motion } from 'framer-motion';

const Contact = () => {
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  function sendEmail(e) {
    e.preventDefault();
    if(name === '' || telefone=== '' || email === '' || message === ''){
      setModalMessage("Preencha todos os campos.");
      setModal(true);
      return;
    }

    const templateParams = {
      from_name: name,
      telefone: telefone,
      email: email,
      message: message
    }
    emailjs.send("service_xtwf4ur", "template_vtrfgki", templateParams, "KMLa2ZrAMfidFqBLn")
    .then((response) => {
      console.log ("Email Enviado", response.status, response.text)
      setName('')
      setTelefone('')
      setEmail('')
      setMessage('')
      setModalMessage("Mensagem enviada com sucesso!");
      setModal(true);

    }, (err) => {
        console.log("Erro: ", err)
    })
  }


  const [name, setName ] = useState('')
  const [telefone, setTelefone] = useState ('')
  const [email, setEmail ] = useState('')
  const [message, setMessage ] = useState('')

  return (
    <motion.section
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={transition1}
    className='section'>
      <div className='container mx-auto h-full'>
        <div className='flex flex-col lg:flex-row h-full items-center justify-start pt-36 gap-x-8 text-center lg:text-left'>
        <div className='lg:flex-1  px-4'>
          <h1 className='h1'>Contato</h1>
            <p className='mb-2'> Em que podemos ajudar? Nos envie uma mensagem,
                                  vamos te <b>responder em 24 horas.</b>
              </p>
          <form className='bg-[#d5e2e6] flex flex-col gap-y-4 flex-1 border rounded-2xl pb-12 p-8 items-start'
          onSubmit={sendEmail}>
          <input className='bg-transparent border-b py-3 outline-none w-full placeholder:text-gray-800 focus:border-gray-800 transition-all'
          type="text"
          placeholder='Nome'
          onChange={(e) => setName (e.target.value)}
          value={name}
          />
          <input className='bg-transparent border-b py-3 outline-none w-full placeholder:text-gray-800 focus:border-gray-800 transition-all'
          type="number"
          placeholder='Telefone'
          onChange={(e) => setTelefone (e.target.value)}
          value={telefone}
          />
          <input className='bg-transparent border-b py-3 outline-none w-full placeholder:text-gray-800 focus:border-gray-800 transition-all'
          type="text"
          placeholder='Email'
          onChange={(e) => setEmail (e.target.value)}
          value={email}
          />
          <textarea className='bg-transparent border-b py-6 outline-none w-full placeholder:text-gray-800 focus:border-gray-800 transition-all resize-none mb-4'
          placeholder='Deixe sua mensagem'
          onChange={(e) => setMessage (e.target.value)}
          value={message}
          ></textarea>
          <button className='btn btn-lg rounded-full'>Enviar Mensagem</button>

          </form>    
        </div>
          </div>
            <ModalMessage
            open={modal}
            message={modalMessage}
            onClick={() => {
          setModal(false);
        }}
      />

    </div>
    </motion.section>
    );

};
export default Contact;
