import Home from '../pages/Home';
import About from '../pages/About';
import Portfolio from '../pages/Portfolio';
import Contact from '../pages/Contact';
import Blog from '../pages/Blog';
import { Routes, Route, useLocation, } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import BlogPageUm from '../pages/BlogPageUm';
import BlogPageDois from '../pages/BlogPageDois';
import BlogPageTres from '../pages/BlogPageTres';
import BlogPageQuatro from '../pages/BlogPageQuatro';

const AnimRoutes = () => {
  
  const location = useLocation();
  return (

    <AnimatePresence initial={true} mode='wait'>
    <Routes key={location.pathname} location={location}>
      <Route path='/' element={<Home />} />
      <Route path='/areasdeatuacao' element={<Portfolio />} />
      <Route path='/sobremim' element={<About />} />
      <Route path='/blog' element={<Blog />} />
      <Route path='/blogpageum' element={<BlogPageUm />} />
      <Route path='/blogpagedois' element={<BlogPageDois />} />
      <Route path='/blogpagetres' element={<BlogPageTres />} />
      <Route path='/blogpagequatro' element={<BlogPageQuatro />} />
            <Route path='/contato' element={<Contact />} />
    </Routes>
    </AnimatePresence>
  );
};

export default AnimRoutes;
